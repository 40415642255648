import { connect } from 'react-redux';
import { TaskItem } from './taskItem';
import {
  destroyTask,
  saveTask,
  toggleTask,
  dragStart,
  dragOver,
  reorderTask
} from '../../../common/redux/tasks/actions';

const mapStateToProps = ({ taskSorting }) => ({ taskSorting });
const mapDispatchToProps = {
  saveTask,
  toggleTask,
  destroyTask,
  dragOver,
  dragStart,
  reorderTask
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskItem);
