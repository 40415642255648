import React from 'react';
import { Card } from '../../shared';
import PropTypes from 'prop-types';

export function LoginForm(props) {
  const handleLogin = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const email = form.email.value;
    const password = form.password.value;
    props.login(email, password);
  };
  return <Card title="Login">
    <form onSubmit={handleLogin}>
      <div className="field">
        <label className="label">E-mail</label>
        <div className="control">
          <input className="input"
            type="email"
            name="email"
            placeholder="user@elepho" />
        </div>
      </div>
      <div className="field">
        <label className="label">Password</label>
        <div className="control">
          <input className="input"
            type="password"
            name="password" />
        </div>
        <small><a onClick={props.gotoForgotPassword} className="mt1">Forgot password?</a></small>
      </div>
      <div className="control">
        <button className="mt1 mr1">Login</button>
        <a onClick={props.gotoRegister} className="button outline mt1">Register</a>
      </div>
    </form>
  </Card>;
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  gotoRegister: PropTypes.func.isRequired,
  gotoForgotPassword: PropTypes.func.isRequired
};
