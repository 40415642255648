import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducers } from './reducers';

export function StoreFactory(GQL, window) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument({ GQL, window })))
  );

  return store;
}

StoreFactory.require = ['GQL', 'window'];
