import React from 'react';
import PropTypes from 'prop-types';
import LoginForm from './loginForm';
import RegisterForm from './registerForm';
import ForgotPasswordForm from './forgotPasswordForm';
import ResetPasswordForm from './resetPasswordForm';
import { switchmap } from '../../common/switchmap';
import { css } from 'emotion';

const style = css`
width: calc(100% - 30px);
max-width: 400px;
margin: 0 auto;
`;

export const router = switchmap()
  .add(/^forgotPassword/, <ForgotPasswordForm />)
  .add(/^resetPassword/, <ResetPasswordForm />)
  .add(/^register?/, <RegisterForm />)
  .add(/.*/, <LoginForm />);

export function LoginPage(props) {
  if (props.currentUser) {
    props.gotoMain();
  }
  return <div className={style}>
    { router.find(props.route.url) }
  </div>;
}

LoginPage.propTypes = {
  route: PropTypes.shape({
    url: PropTypes.string
  }),
  currentUser: PropTypes.object,
  gotoMain: PropTypes.func
};