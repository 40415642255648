import React from 'react';
import PropTypes from 'prop-types';
import { ColourPicker } from '../../shared';

export function LabelForm(props) {
  const onSubmit = (e) => {
    e.preventDefault();
    const label = { 
      name: e.currentTarget.name.value,
      colour: e.currentTarget.colour.value
    };
    if (props.label.id) {
      label.id = props.label.id;
    }
    props.saveLabel(label).then(() => {
      return props.gotoMain();
    });
  };
  const title = props.label.id ? 'Edit label' : 'New label';
  return <form onSubmit={onSubmit}>
    <h5 className="m0 mb2">{ title }</h5>
    <label htmlFor="name">Name</label>
    <input type="text" id="name" name="name" defaultValue={props.label.name} />
    <label htmlFor="colour">Colour</label>
    <ColourPicker id="colour" name="colour" defaultValue={props.label.colour} />
    <button className="mt1 right">Save</button>
  </form>;
}

LabelForm.propTypes = {
  label: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    colour: PropTypes.string
  }),
  saveLabel: PropTypes.func,
  gotoMain: PropTypes.func
};

LabelForm.defaultProps = {
  label: { name: '' },
  saveLabel: () => Promise.reject()
};