export class EventBus {
  constructor() {
    this._listeners = {};
  }
  subscribe(eventName, cb) {
    if(!this._listeners[eventName]) {
      this._listeners[eventName] = [];
    }
    this._listeners[eventName].push(cb);
    return () => this.unsubscribe(eventName, cb);
  }
  unsubscribe(eventName, cb) {
    if(!this._listeners[eventName]) {
      this._listeners[eventName] = [];
    }
    const index = this._listeners[eventName].findIndex(_cb => _cb === cb);
    this._listeners[eventName].splice(index, 1);
  }
  emit(eventName, data) {
    if(!this._listeners[eventName]) {
      this._listeners[eventName] = [];
    }
    this._listeners[eventName].forEach(cb => cb(data));
  }
}