import React from 'react';
import PropTypes from 'prop-types';

export function Toasts(props) {
  return <div id="notifications">
    <div className="notification-container">
      {props.toasts.map(toast => (
        <div key={toast.timestamp} className={"notification " + toast.level}>
          <a className="close">&times;</a>
          { toast.message }
        </div>
      ))}
    </div>
  </div>;
}

Toasts.propTypes = {
  toasts: PropTypes.arrayOf(PropTypes.shape({
    timestamp: PropTypes.number,
    message: PropTypes.string,
    level: PropTypes.string
  }))
};