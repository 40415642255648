import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../shared';

export function RegisterForm(props) {
  const handleRegister = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const email = form.email.value;
    const password = form.password.value;
    props.register(email, password);
  };
  return <Card title="Register">
    <form onSubmit={handleRegister}>
      <label className="label">E-mail</label>
      <input className="input"
        type="email"
        name="email"
        placeholder="user@elepho" />
      <label className="label">Password</label>
      <input className="input"
        type="password"
        name="password" />
      <button className="mt1 mr1">Register</button>
      <a onClick={props.gotoLogin} className="button outline mt1">Login</a>
    </form>
  </Card>;
}

RegisterForm.propTypes = {
  register: PropTypes.func.isRequired,
  gotoLogin: PropTypes.func.isRequired
};