// import { Auth } from './auth';
// import { Router } from './router';
import { Container } from './container';
import { EventBus } from './eventBus';
import { HttpService, FetchWrapper } from './http';
import { GQL } from './data';
import { StoreFactory } from './redux/store';

const app = new Container();
app.factory('window', () => window);
app.factory('document', () => window.document);
app.factory('EventBus', () => EventBus);
app.factory('store', StoreFactory);
// app.service('auth', Auth);
// app.service('router', Router);
app.service('http', HttpService);
app.service('httpBackend', FetchWrapper);
app.service('GQL', GQL);

export { app };