import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { omit } from 'lodash';

const style = css`
background: white;
border-radius: 5px;
box-shadow: 0 0 5px rgba(0,0,0, 0.2);
.card-header {
  border-bottom: solid 1px #efefef;
  margin: 0;
  padding: 10px 15px;
  font-size: 1.8em;
}
.card-content {
  padding: 15px;
}
`;
export function Card(props) {
  const hasTitle = props.title && props.title.length;
  const cardProps = omit(props, ['title']);
  cardProps.className = `${cardProps.className || ''} ${style}`;
  return <section { ...cardProps }>
    { hasTitle ? <h1 className="card-header">{props.title}</h1> : '' }
    <div className="card-content">{ props.children }</div>
  </section>;
}

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};