import React from 'react';
import PropTypes from 'prop-types';
import NavBar from './navbar';
import LoginPage from './loginPage';
import Toasts from './toasts';
import MainPage from './mainPage';
import './app.scss';
import { switchmap } from '../common/switchmap';
import { css } from 'emotion';

const style = css`
margin: 0 auto;
max-width: 900px;
`;

const loginPages = /^(login|register|forgotPassword|resetPassword)/;
export const router = switchmap()
  .add(loginPages, <LoginPage />)
  .add(/^(main|tasks)?/, <MainPage />)
  .add(/.*/, <p className="notification is-danger">Page not found</p>);

export function App(props) {
  if (!loginPages.test(props.route.path) && !props.currentUser) {
    setTimeout(props.gotoLogin);
    return <div />;
  }
  const page = router.find(props.route.path);
  return (<>
    <img id="logo" src="/img/logo.png" />
    <NavBar />
    <div className={style}>{ page }</div>
    <Toasts />
  </>);
}

App.propTypes = {
  route: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.object
  }),
  currentUser: PropTypes.object,
  gotoLogin: PropTypes.func
};
