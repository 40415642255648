import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../shared';

export function ResetPasswordForm(props) {
  const handle = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const token = props.route.params.token;
    const password = form.password.value;
    props.resetPassword(token, password);
  };
  return <Card title="Reset password">
    <form onSubmit={handle}>
      <label>New password</label>
      <input 
        type="password"
        name="password" />
      <button className="button is-primary mt1">Reset password</button>
    </form>
  </Card>;
}

ResetPasswordForm.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.object
  }),
  resetPassword: PropTypes.func.isRequired
};