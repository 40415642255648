import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const style = css`
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    &.is-active {
      display: block;
    }
  }
  .outline {
    position: fixed;
    border: solid 1px pink;
  }
  ul {
    svg {
      color: #6f6f6f;
    }
    position: fixed;
    width: 200px;
    margin: 0;
    background: white;
    border-radius: 3px;
    box-shadow: 0 0 5px #afafaf;
    li {
      padding: 2px 5px;
      border-radius: 3px;
    }
    li:hover {
      background: #fcfcfc;
    }
    a, a:link, a:visited {
      display: block;
      color: black;
      text-decoration: none;
      padding: -2px -5px;
      cursor: pointer;
    }
  }
`;

export class Dropdown extends Component {
  state = { showMenu: false, position: { left: 0, top: 0 } };
  backdropRef = React.createRef();
  menuRef = React.createRef();
  toggleMenu = (e) => {
    const bb = e.currentTarget.getBoundingClientRect();
    const showMenu = !this.state.showMenu;
    this.setState({ showMenu }, () => {
      if (showMenu) {
        const position = this.calcPosition(bb);
        this.setState({ position });
      }
    });
  };
  stopEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }
  calcPosition(bb) {
    let left = bb.left;
    let top = bb.top + bb.height + 5;
    const menu = this.menuRef.current.getBoundingClientRect();
    const backdrop = this.backdropRef.current.getBoundingClientRect();
    if (this.props.right) {
      left = bb.left + bb.width - menu.width;
    }
    if (this.props.top) {
      top = bb.top - menu.height - 5;
    }
    left -= Math.max(0, left + menu.width - backdrop.width);
    top -= Math.max(0, top + menu.height - backdrop.height);
    return { left, top };
  }
  render() {
    const triggerEl = this.props.button ?
      <button onClick={this.toggleMenu}>
        { this.props.trigger }
      </button> :
      <a onClick={this.toggleMenu}>
        { this.props.trigger }
      </a>;

    return <span className={style}>
      { triggerEl }
      <div className={this.state.showMenu ? 'backdrop is-active' : 'backdrop'}
        onClick={this.toggleMenu}
        onWheel={this.stopEvent}
        ref={this.backdropRef}>
        <ul className="list-reset" style={ this.state.position } ref={this.menuRef}>
          { this.props.children }
        </ul>
      </div>
    </span>;
  }
}

Dropdown.propTypes = {
  trigger: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  top: PropTypes.bool,
  right: PropTypes.bool,
  button: PropTypes.bool,
  width: PropTypes.number
};

Dropdown.defaultProps = {
  width: 200
};

export function DropdownItem(props) {
  return <li>{ props.children }</li>;
}

DropdownItem.propTypes = {
  children: PropTypes.node
};