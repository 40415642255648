import { FETCHED_DATA } from './actions';
import { forEach, pick, map } from 'lodash';

export default function reducer(state, action) {
  if (action.type !== FETCHED_DATA) {
    return state;
  }
  const s = {
    projects: [],
    projectsById: {},
    labels: [],
    labelsById: {},
    tasks: [],
    tasksById: {},
    taskSorting: {},
    labelTasks: {}
  };

  forEach(action.projects, (project) => {
    s.projectsById[project.id] = project;
    s.projects.push(project.id);
  });
  forEach(action.labels, (label) => {
    s.labelsById[label.id] = pick(label, ['id', 'name', 'colour']);
    s.labelTasks[label.id] = map(label.tasks, 'id');
    s.labels.push(label.id);
  });
  forEach(action.tasks, (task) => {
    s.tasksById[task.id] = task;
    s.tasks.push(task.id);
  });

  return {
    ...state,
    ...s
  };
}
