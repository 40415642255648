import React from 'react';
import PropTypes from 'prop-types';
import { IconLink, Dropdown, DropdownItem } from '../../shared';
import Icon from '@mdi/react';
import { css } from 'emotion';
import { mdiCheckboxBlankCircle, mdiCircleEditOutline, mdiPlus, mdiDotsVertical, mdiDelete } from '@mdi/js';

const style = css`
  margin: 0;
  padding: 5px 0;
  list-style-type: none;
  >li { padding: 5px 3px; border-radius: 5px; }
  .highlight { background: #f0f0f0; }
  .menu { color: #e0e0e0; }
`;
export function LabelList(props) {
  const onClickNew = (e) => {
    e.preventDefault();
    props.gotoNewLabel();
  };
  const onClickLabel = (label) => (e) => {
    e.preventDefault();
    props.gotoLabelTasks(label);
  };
  const onEditLabel = label => e => {
    e.preventDefault();
    props.gotoEditLabel(label);
  };
  const onDestroyLabel = (label) => (e) => {
    e.preventDefault();
    swal({
      buttons: true,
      dangerMode: true,
      icon: 'warning',
      title: 'Delete label',
      text: 'Are you sure you want to delete this label? Its tasks won\'t be deleted'
    }).then((confirm) => {
      if (confirm) {
        props.destroyLabel(label);
        props.gotoMain();
      }
    });
  };
  const isHighlighted = (id) => props.route.params.labelId === id ? 'highlight' : '';
  return <>
    <h5>Labels</h5>
    <ul className={style}>
      { props.labels.map(label => (
        <li key={label.id} className={isHighlighted(label.id)}>
          <IconLink path={mdiCheckboxBlankCircle} onClick={onClickLabel(label)} iconStyle={({ color: label.colour })}>
            {label.name}
          </IconLink>
          <span className="right">
            <Dropdown trigger={<Icon path={mdiDotsVertical} size={.75} className="menu" /> } right>
              <DropdownItem><IconLink onClick={onEditLabel(label)} path={mdiCircleEditOutline}>Edit</IconLink></DropdownItem>
              <DropdownItem><IconLink onClick={onDestroyLabel(label)} path={mdiDelete}>Delete</IconLink></DropdownItem>
            </Dropdown>
          </span>
        </li>
      ))}
      <li><IconLink onClick={onClickNew} path={mdiPlus}>New label</IconLink></li>
    </ul>
  </>;
}

LabelList.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    colour: PropTypes.string,
    name: PropTypes.string
  })),
  route: PropTypes.shape({
    params: PropTypes.object
  }),
  destroyLabel: PropTypes.func,
  gotoEditLabel: PropTypes.func,
  gotoMain: PropTypes.func,
  gotoLabelTasks: PropTypes.func,
  gotoNewLabel: PropTypes.func
};