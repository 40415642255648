export const FETCHED_DATA = 'FETCHED_DATA';
import { notify, LEVELS } from '../toasts/actions';

export function fetchData() {
  return (dispatch, getState, { GQL }) => {
    return GQL.getAll().then(
      data => dispatch({ type: FETCHED_DATA, ...data }),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}