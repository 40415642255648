import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '../../shared';

export function ForgotPasswordForm(props) {
  const handle = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const email = form.email.value;
    props.requestPasswordReset(email);
  };
  return <Card title="Forgot password">
    <form onSubmit={handle}>
      <label className="label">E-mail</label>
      <input className="input"
        type="email"
        name="email"
        placeholder="user@elepho" />
      <button className="button is-primary mt1">Request reset</button>
    </form>
  </Card>;
}

ForgotPasswordForm.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired
};