import { connect } from 'react-redux';
import { get } from 'lodash';
import { ProjectForm } from './projectForm';
import { saveProject } from '../../../common/redux/projects/actions';
import { gotoMain } from '../../../common/redux/route/actions';

const mapStateToProps = (state) => {
  const projectId = get(state, 'route.params.projectId');
  const props = {};
  if (projectId) {
    props.project = state.projectsById[projectId];
  }
  return props;
};
const mapDispatchToProps = { saveProject, gotoMain };
export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);