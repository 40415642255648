import { reduce, isObject, attempt, isError } from 'lodash';

export const GOTO = 'GOTO';

export const gotoLogin = () => (dispatch) => dispatch(goto('login'));
export const gotoRegister = () => (dispatch) => dispatch(goto('register'));
export const gotoForgotPassword = () => (dispatch) => dispatch(goto('forgotPassword'));
export const gotoMain = () => (dispatch) => dispatch(goto('main'));
export const gotoNewProject = () => (dispatch) => dispatch(goto('main', { newProject: true }));
export const gotoNewLabel = () => (dispatch) => dispatch(goto('main', { newLabel: true }));
export const gotoEditProject = (project) => (dispatch) => dispatch(goto('main', { projectId: project.id }));
export const gotoEditLabel = (label) => (dispatch) => dispatch(goto('main', { labelId: label.id }));
export const gotoAllTasks = () => (dispatch) => dispatch(goto('tasks'));
export const gotoProjectTasks = (project) => (dispatch) => dispatch(goto('tasks', { projectId: project.id }));
export const gotoLabelTasks = (label) => (dispatch) => dispatch(goto('tasks', { labelId: label.id }));

export function goto(path, params = {}, title = '') {
  return (dispatch, getState, { window }) => {
    const url = path + formatAsUrlParams(params);
    window.history.pushState({}, title, `#${url}`);
    return dispatch({
      type: GOTO,
      path,
      url,
      params
    });
  };
}

export function goBack() {
  return (dispatch, getState, { window }) => {
    window.history.back();
    return dispatch(updateFromURL());
  };
}

export function updateFromURL() {
  return (dispatch, getState, { window }) => {
    let url = window.location.hash.slice(1);
    let path = url;
    let params = {};
    const paramsIndex = url.indexOf('?');
    if (paramsIndex >= 0) {
      params = parseFromUrlParams(url.slice(paramsIndex + 1));
      path = url.slice(0, paramsIndex);
    }
    return dispatch({
      type: GOTO,
      path,
      url,
      params
    });
  };
}

function encodeParameterValue(value) {
  if (isObject(value)) {
    value = JSON.stringify(value);
  }
  return encodeURIComponent(value);
}

function decodeParameterValue(value) {
  const parsed = attempt(JSON.parse, decodeURIComponent(value));
  return isError(parsed) ? value : parsed;
}

function formatAsUrlParams(params = {}) {
  const pairs = reduce(params, (acc, value, key) => {
    if (value === true) {
      return acc.concat(key);
    }
    return acc.concat(`${key}=${encodeParameterValue(value)}`);
  }, []);
  return pairs.length ? '?' + pairs.join('&') : '';
}

function parseFromUrlParams(params = '') {
  return params.split('&').reduce((acc, pair) => {
    const [key, value] = pair.split('=').concat(true);
    return Object.assign(acc, { [key]: decodeParameterValue(value) });
  }, {});
}