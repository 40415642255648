import { GOTO } from './actions';

const defaultRoute = {
  url: 'main',
  path: 'main',
  params: {}
};

export function route(state = defaultRoute, action) {
  switch(action.type) {
    case GOTO:
      return {
        url: action.url,
        path: action.path,
        params: action.params
      };
  }
  return state;
}
