import { EDIT_PROJECT, DESTROY_PROJECT, CREATE_PROJECT } from './actions';

export function projects(state = [], action) {
  switch(action.type) {
    case CREATE_PROJECT:
      return state.concat(action.project.id);
    case DESTROY_PROJECT:
      return state.filter(id => id !== action.project.id);
  }
  return state;
}

export function projectsById(state = {}, action) {
  const newState = { ...state };
  switch(action.type) {
    case CREATE_PROJECT:
    case EDIT_PROJECT:
      return Object.assign(newState, { [action.project.id]: action.project });
    case DESTROY_PROJECT:
      delete newState[action.project.id];
      return newState;
  }
  return state;
}

