import React from 'react';
import { NavBar } from './navbar';
import { pick } from 'lodash';
import { connect } from 'react-redux';
import { logout } from '../../common/redux/auth/actions';
import { goBack, gotoMain } from '../../common/redux/route/actions';

const mapStateToProps = (state) => {
  const props = pick(state, ['currentUser', 'route']);
  return props;
};
const mapDispatchToProps = { logout, goBack, gotoMain };
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);