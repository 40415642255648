import { connect } from 'react-redux';
import { TaskList } from './taskList';
import { values, filter, map, reduce, get, orderBy } from 'lodash';
import { switchmap } from '../../../common/switchmap';
import { destroyTask } from '../../../common/redux/tasks/actions';

const has = (key) => (obj) => obj && obj.hasOwnProperty(key);
const attachLabels = (state) => (task) => {
  const labels = reduce(
    state.labelTasks,
    (acc, taskIds, labelId) => {
      if (taskIds.includes(task.id)) {
        acc.push(state.labelsById[labelId]);
      }
      return acc;
    },
    []
  );
  task.labels = labels;
  return task;
};

const taskFilter = switchmap()
  .add(has('projectId'), (state) => {
    const projectId = state.route.params.projectId;
    return orderBy(
      filter(state.tasksById, { projectId }).map(attachLabels(state)),
      ['order'],
      ['asc']
    );
  })
  .add(has('labelId'), (state) => {
    const labelId = state.route.params.labelId;
    const findTask = (id) => state.tasksById[id];
    return map(state.labelTasks[labelId], findTask).map(attachLabels(state));
  })
  .add(() => true, (state) => values(state.tasksById).map(attachLabels(state)));

const titleMap = switchmap()
  .add(has('projectId'), (state) =>
    get(state.projectsById, [state.route.params.projectId, 'name'])
  )
  .add(has('labelId'), (state) =>
    get(state.labelsById, [state.route.params.labelId, 'name'])
  )
  .add(() => true, () => 'All tasks');

const mapStateToProps = (state) => ({
  title: titleMap.find(state.route.params)(state),
  tasks: taskFilter.find(state.route.params)(state),
  route: state.route
});
const mapDispatchToProps = { destroyTask };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskList);
