import { OrderedMap } from 'immutable';
import { isRegExp, isFunction } from 'lodash';

export function switchmap(map = OrderedMap()) {
  return {
    concat(other) {
      return switchmap(map.concat(other));
    },
    add(key, value) {
      return switchmap(map.set(key, value));
    },
    map(fn) {
      return switchmap(map.map(fn));
    },
    find(needle) {
      return map.find((val, key) => {
        if (isRegExp(key)) {
          return key.test(needle);
        } else if (isFunction(key)) {
          return key(needle);
        }
        return key === needle;
      });
    }
  };
}