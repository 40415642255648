import React from 'react';
import PropTypes from 'prop-types';
import TaskItem from '../taskItem';
import { IconLink } from '../../shared';
import { mdiDelete } from '@mdi/js';
import swal from 'sweetalert';

export function TaskList(props) {
  const todo = props.tasks.filter((t) => !t.completedAt);
  const completed = props.tasks.filter((t) => !!t.completedAt);
  const isListingProject = !!props.route.params.projectId;
  const newTask = { labels: [], projectId: props.route.params.projectId };
  const deleteCompleted = () => {
    swal({
      buttons: true,
      dangerMode: true,
      icon: 'warning',
      title: 'Delete completed tasks',
      text: 'Are you sure you want to delete all completed tasks?'
    }).then(() => completed.forEach((task) => props.destroyTask(task)));
  };
  return (
    <>
      <h5 className="m0 mb2">{props.title}</h5>
      {!completed.length && !todo.length ? (
        <div className="centerMsg">No tasks here (yet)</div>
      ) : null}
      <ul className="list-reset">
        {isListingProject ? <TaskItem task={newTask} dark /> : null}
        {todo.map((task) => (
          <TaskItem task={task} key={task.id} sortable={isListingProject} />
        ))}
      </ul>
      {props.showCompleted && completed.length === 0 ? null : (
        <>
          <h2 className="h3">
            Completed{' '}
            <IconLink path={mdiDelete} className="right" onClick={deleteCompleted} />
          </h2>
          <hr />
          <ul className="list-reset">
            {completed.map((task) => (
              <TaskItem task={task} key={task.id} />
            ))}
          </ul>
        </>
      )}
    </>
  );
}

TaskList.propTypes = {
  title: PropTypes.string,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
      deadline: PropTypes.string,
      completedAt: PropTypes.string,
      projectId: PropTypes.number,
      labels: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          colour: PropTypes.string
        })
      )
    })
  ),
  route: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.object
  }),
  destroyTask: PropTypes.func,
  showCompleted: PropTypes.bool
};

TaskList.defaultProps = {
  title: '',
  showCompleted: true
};
