import React from 'react';
import PropTypes from 'prop-types';
import { switchmap } from '../../common/switchmap';
import ProjectList from './projectList';
import ProjectForm from './projectForm'; 
import LabelList from './labelList';
import LabelForm from './labelForm';
import { css } from 'emotion';
import { intersection } from 'lodash';
import TaskList from './taskList';

const style = css`
  width: calc(100% - 30px);
  margin: 0 15px 15px 15px;
  display: flex;
  align-items: stretch;
  background: white;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0 0 5px rgba(0,0,0, 0.2);
  aside {
    padding: 15px;
    margin: 0;
    flex-shrink: 0;
    h5:first-of-type {
      margin-top: 0px;
    }
    h5:not(:first-of-type) {
      margin-top: 25px;
    }
  }
  .main {
    padding: 15px;
    position: relative;
    flex-grow: 1;
  }
  .centerMsg {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    font-size: 2em;
    color: #e0e0e0;
    text-align: center;
  }
  @media (max-width: 767px) {
    .centerMsg { display: none; }
    aside.focus-main { display: none; }
    .main.focus-aside { display: none; }
  }
  @media (min-width: 768px) {
    aside {
      width: 300px;
      border-right: solid 1px #efefef;
    }
  }
`;

const mainParam = key => obj => obj && obj.path !== 'tasks' && obj.params && obj.params.hasOwnProperty(key);
const router = switchmap()
  .add(mainParam('projectId'), <ProjectForm />)
  .add(mainParam('newProject'), <ProjectForm />)
  .add(mainParam('labelId'), <LabelForm />)
  .add(mainParam('newLabel'), <LabelForm />)
  .add((route) => route.path === 'tasks', <TaskList />)
  .add(() => true, <div>
    <div className="centerMsg">Select a project or label</div>
  </div>);

export function MainPage(props) {
  const isFormVisible = intersection(Object.keys(props.route.params), ['projectId', 'labelId', 'newProject', 'newLabel']).length > 0;
  const isTaskListVisible = false;
  const getMainClass = () =>  `main ${ isFormVisible || isTaskListVisible ? 'focus-main' : 'focus-aside'}`;
  const getAsideClass = () => isFormVisible || isTaskListVisible ? 'focus-main' : 'focus-aside';
  return <section className={style}>
    <aside className={getAsideClass()}>
      <ProjectList />
      <LabelList />
    </aside>
    <div className={getMainClass()}>
      { router.find(props.route) }
    </div>
  </section>;
}

MainPage.propTypes = {
  route: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.object
  })
};