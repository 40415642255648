import {
  CREATE_TASK,
  EDIT_TASK,
  DESTROY_TASK,
  REORDER_TASKS,
  DRAG_OVER,
  DRAG_START,
  DROP
} from './actions';

export function tasks(state = [], action) {
  switch (action.type) {
    case CREATE_TASK:
      return state.concat(action.task.id);
    case DESTROY_TASK:
      return state.filter((id) => id !== action.task.id);
  }
  return state;
}

export function tasksById(state = {}, action) {
  const newState = { ...state };
  switch (action.type) {
    case CREATE_TASK:
    case EDIT_TASK:
      return Object.assign(newState, { [action.task.id]: action.task });
    case DESTROY_TASK:
      delete newState[action.task.id];
      return newState;
    case REORDER_TASKS:
      return action.tasks.reduce(
        (tasks, task) => ({
          ...tasks,
          [task.id]: task
        }),
        newState
      );
  }
  return state;
}

export function taskSorting(state = {}, action) {
  switch (action.type) {
    case DRAG_OVER:
      return { ...state, order: action.task.order };
    case DRAG_START:
      return { task: action.task };
    case REORDER_TASKS:
      return {};
  }
  return state;
}
