import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Card } from './card';
import { omit } from 'lodash';

const style = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.darken {
    background: rgba(0,0,0,0.2);
  }
  >section {
    max-width: 400px;
    margin: 0 15px;
  }
`;
export function Dialog(props) {
  if(!props.open) return '';
  const cardProps = omit(props, ['darken', 'onClickBackdrop']);
  cardProps.onClick = (e) => e.stopPropagation();
  return <div className={ props.darken ? style + ' darken' : style } onClick={props.onClickBackdrop}>
    <Card { ...cardProps } />
  </div>;
}

Dialog.propTypes = {
  open: PropTypes.bool,
  darken: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  onClickBackdrop: PropTypes.func
};

Dialog.defaultProps = {
  open: false,
  onClickBackdrop: () => null
};