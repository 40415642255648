class ContainerItem {
  constructor(name, fn, dependencies = []) {
    this.name = name;
    this.fn = fn;
    this.dependencies = dependencies;
  }
  create() {
    throw new Error('Implement the create method');
  }
}

class Factory extends ContainerItem {
  create(container) {
    if (this._cache) {
      return this._cache;
    }
    const depInstances = this.dependencies.map((d) => container.get(d));
    return (this._cache = this.fn(...depInstances));
  }
}

class Singleton extends ContainerItem {
  create(container) {
    if (this._cache) {
      return this._cache;
    }
    const depInstances = this.dependencies.map((d) => container.get(d));
    return (this._cache = new this.fn(...depInstances));
  }
}

export class Container {
  constructor() {
    this.items = new Map();
    this.factory('Container', () => this);
  }
  factory(name, fn) {
    this.items.set(name, new Factory(name, fn, fn.require));
  }
  service(name, fn) {
    this.items.set(name, new Singleton(name, fn, fn.require));
  }
  run(dependencies, fn) {
    if (typeof dependencies === 'function') {
      fn = dependencies;
      dependencies = fn.require || [];
    }
    const depInstances = dependencies.map((item) => this.get(item));
    return fn.apply(null, depInstances);
  }
  bootstrap() {
    for (let [, item] of this.items) {
      item.create(this);
    }
    return this;
  }
  get(name) {
    if (!this.items.has(name)) {
      throw new Error(`Dependency not found: ${name}`);
    }
    return this.items.get(name).create(this);
  }
}
