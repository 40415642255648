import { CREATE_LABEL, EDIT_LABEL, DESTROY_LABEL, ATTACH_LABEL, DETACH_LABEL } from './actions';

export function labels(state = [], action) {
  switch(action.type) {
    case CREATE_LABEL:
      return state.concat(action.label.id);
    case DESTROY_LABEL:
      return state.filter(id => id !== action.label.id);
  }
  return state;
}

export function labelsById(state = {}, action) {
  let newState = { ...state };
  switch(action.type) {
    case CREATE_LABEL:
    case EDIT_LABEL:
      return Object.assign(newState, { [action.label.id]: action.label });
    case DESTROY_LABEL:
      delete newState[action.label.id];
      return newState;
  }
  return state;
}

export function labelTasks(state = {}, action) {
  const { label, task } = action;
  const newState = { ...state };
  switch(action.type) {
    case ATTACH_LABEL:
      newState[label.id] = (state[label.id] || []).concat(task.id);
      return newState;
    case DETACH_LABEL:
      newState[label.id] = (state[label.id] || []).filter(id => id !== task.id);
      return newState;
  }
  return state;
}