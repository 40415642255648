import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from './dialog';
import Icon from '@mdi/react';
import { mdiCheckboxBlankCircle as circle } from '@mdi/js';

export class ColourPicker extends Component {
  state = { open: false, value: '#307473' };
  onClick = () => {
    this.setState({ open: true });
  };
  onClose = () => {
    this.setState({ open: false });
  };
  onPickColor = (e) => {
    const value = e.currentTarget.style.color;
    this.setState({ value, open: false });
    this.props.onChange(value);
  };
  render() {
    const colour = this.state.value || this.props.value || this.props.defaultValue;
    const inputProps = {
      name: this.props.name,
      id: this.props.id,
      type: 'hidden',
      value: colour,
      onChange: () => null
    };
    const dialogProps = {
      open: this.state.open,
      title: 'Pick colour',
      darken: true,
      onClickBackdrop: this.onClose
    };
    const iconProps = (color) => ({
      path: circle,
      size: 1,
      style: { color },
      onClick: this.onPickColor
    });
    return React.createElement(
      'div',
      null,
      React.createElement(Icon, { ...iconProps(colour), onClick: this.onClick }),
      React.createElement('input', inputProps),
      React.createElement(
        Dialog,
        dialogProps,
        React.createElement(Icon, iconProps('#f0f2ef')),
        React.createElement(Icon, iconProps('#8baaad')),
        React.createElement(Icon, iconProps('#22333b')),
        React.createElement(Icon, iconProps('#aaa85f')),
        React.createElement(Icon, iconProps('#c08552')),
        React.createElement(Icon, iconProps('#e5b561'))
      )
    );
  }
}

ColourPicker.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

ColourPicker.defaultProps = {
  name: 'colour',
  onChange: () => null
};
