import { connect } from 'react-redux';
import { get } from 'lodash';
import { LabelForm } from './labelForm';
import { saveLabel } from '../../../common/redux/labels/actions';
import { gotoMain } from '../../../common/redux/route/actions';

const mapStateToProps = (state) => {
  const labelId = get(state, 'route.params.labelId');
  const props = {};
  if (labelId) {
    props.label = state.labelsById[labelId];
  }
  return props;
};
const mapDispatchToProps = { saveLabel, gotoMain };
export default connect(mapStateToProps, mapDispatchToProps)(LabelForm);