import { notify, LEVELS } from '../toasts/actions';

export const CREATE_LABEL = 'CREATE_LABEL';
export const EDIT_LABEL = 'EDIT_LABEL';
export const DESTROY_LABEL = 'DESTROY_LABEL';
export const ATTACH_LABEL = 'ATTACH_LABEL';
export const DETACH_LABEL = 'DETACH_LABEL';

export function saveLabel(label) {
  return (dispatch, getState, { GQL }) => {
    const mutation = label.id ? 'updateLabel' : 'createLabel';
    const action = label.id ? editLabel : createLabel;
    return GQL[mutation](label).then(
      data => dispatch(action(data[mutation])),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}


export function createLabel(label) {
  return {
    type: CREATE_LABEL,
    label
  };
}

export function editLabel(label) {
  return {
    type: EDIT_LABEL,
    label
  };
}

export function destroyLabel(label) {
  return (dispatch, getState, { GQL }) => {
    return GQL.destroyLabel(label).then(
      () => dispatch({ type: DESTROY_LABEL, label }),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}

export function attachLabel(label, task) {
  return (dispatch, getState, { GQL }) => {
    return GQL.attachLabel({ labelId: label.id, taskId: task.id }).then(
      () => dispatch({ type: ATTACH_LABEL, label, task }),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}

export function detachLabel(label, task) {
  return (dispatch, getState, { GQL }) => {
    return GQL.detachLabel({ labelId: label.id, taskId: task.id }).then(
      () => dispatch({ type: DETACH_LABEL, label, task }),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}
