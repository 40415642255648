import { combineReducers } from 'redux';
import * as auth from './auth/reducers';
import * as labels from './labels/reducers';
import * as projects from './projects/reducers';
import * as tasks from './tasks/reducers';
import * as toasts from './toasts/reducers';
import * as route from './route/reducers';
import DataReducer from './data/reducers';

const slicedReducers = combineReducers({
  ...auth,
  ...labels,
  ...projects,
  ...tasks,
  ...toasts,
  ...route
});

export function reducers(state, action) {
  const temp = DataReducer(state, action);
  return slicedReducers(temp, action);
} 