import { NOTIFY, CLEAR_TOAST, LEVELS } from './actions';

export function toasts(state = {}, action) {
  const newState = { ...state };
  switch(action.type) {
    case NOTIFY:
      return Object.assign(newState, {
        [action.timestamp]: {
          visible: true,
          timestamp: action.timestamp,
          message: action.message,
          level: action.level
        }
      });
    case CLEAR_TOAST:
      return Object.assign(newState, {
        [action.timestamp]: {
          ...newState[action.timestamp],
          visible: false
        }
      });
  }
  return state;
}
