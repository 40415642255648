import { connect } from 'react-redux';
import { ProjectList } from './projectList';
import { gotoNewProject, gotoEditProject, gotoProjectTasks, gotoMain } from '../../../common/redux/route/actions';
import { destroyProject } from '../../../common/redux/projects/actions';

const mapStateToProps = (state) => ({ projects: state.projects.map(id => state.projectsById[id]), route: state.route });
const mapDispatchToProps = {
  gotoEditProject,
  gotoNewProject,
  gotoProjectTasks,
  gotoMain,
  destroyProject
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);