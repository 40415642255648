import { connect } from 'react-redux';
import { LabelList } from './labelList';
import { gotoNewLabel, gotoEditLabel, gotoLabelTasks, gotoMain } from '../../../common/redux/route/actions';
import { destroyLabel } from '../../../common/redux/labels/actions';

const mapStateToProps = (state) => ({ labels: state.labels.map(id => state.labelsById[id]), route: state.route });
const mapDispatchToProps = {
  gotoEditLabel,
  gotoNewLabel,
  gotoMain,
  gotoLabelTasks,
  destroyLabel
};
export default connect(mapStateToProps, mapDispatchToProps)(LabelList);