import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as RouteActions from './common/redux/route/actions';
import * as AuthActions from './common/redux/auth/actions';
import * as DataActions from './common/redux/data/actions';
import App from './app';
import { app } from './common';
import { get } from 'lodash';

window.elepho = app;
app.bootstrap().run(['window', 'store', 'httpBackend'], (window, store, httpBackend) => {
  bootstrapRouting(window, store);
  setupAuthInterceptor(httpBackend, store);
  setupLogoutInterceptor(httpBackend, store);
  restoreAuthToken(store);
  registerSW(window);

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('app')
  );
});

function setupAuthInterceptor(httpBackend, store) {
  httpBackend.addRequestInterceptor((request) => {
    const { currentUser } = store.getState();
    if(currentUser && currentUser.token) {
      request.headers.Authorization = currentUser.token;
    }
    return request;
  });
}

function restoreAuthToken(store) {
  const token = localStorage.getItem('token');
  if (token) {
    store.dispatch(AuthActions.setToken(token));
    store.dispatch(AuthActions.refreshToken());
  }
}

function setupLogoutInterceptor(httpBackend, store) {
  httpBackend.addResponseInterceptor((response) => {
    if (get(response, 'body.errors[0].message') === 'Unauthorized') {
      store.dispatch(AuthActions.logout());
    }
    return response;
  });
}

function bootstrapRouting(window, store) {
  window.onpopstate = () => store.dispatch(RouteActions.updateFromURL());
  store.dispatch(RouteActions.updateFromURL());
}

function registerSW(window) {
  if ('serviceWorker' in navigator && ENV === 'production') {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js');
    });
  }
}