import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import LabelPicker from './../labelPicker';
import { Icon } from '@mdi/react';
import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiPlus } from '@mdi/js';

const style = (dark) => css`
  ${dark
    ? `
  background: #6c4260;
  color: white;
  padding: 5px 5px 5px 7px;
  margin: 0 -6px;
  border-radius: 5px;
  .eip {
    background: transparent;
    color: white;
    &:focus {
      background: transparent;
    }
  }
  `
    : ''}
  display: flex;
  .description {
    flex-grow: 1;
  }
  .completed {
    text-decoration: line-through;
  }
  .toggle {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    top: -2px;
  }
  .eip {
    padding: 0;
    border: 0;
    &:focus {
      box-shadow: unset;
    }
  }
  &.dropzone-up {
    border-top: solid 35px #945d6b;
    padding-top: 5px;
  }
  &.dropzone-down {
    border-bottom: solid 35px #945d6b;
  }
`;

const KEYMAP = {
  ENTER: 13,
  BACKSPACE: 8
};
export function TaskItem(props) {
  let icon = mdiPlus;
  if (props.task.id) icon = mdiCheckboxBlankCircleOutline;
  if (props.task.completedAt) icon = mdiCheckboxMarkedCircle;
  const toggleTask = (e) => {
    e.preventDefault();
    props.toggleTask(props.task);
  };
  const save = (e) => {
    const target = e.currentTarget;
    const description = target.value;
    const isCreate = !props.task.id;
    if (description.length < 1 || description === props.task.description) return;
    props.saveTask({ ...props.task, description }).then(() => {
      if (isCreate) {
        target.value = '';
      }
    });
  };
  const onKeyDown = (e) => {
    const value = e.currentTarget.value;
    switch (e.which) {
      case KEYMAP.ENTER:
        return save(e);
      case KEYMAP.BACKSPACE:
        if (props.task.id && value.length === 0) {
          props.destroyTask(props.task);
        }
        break;
    }
  };
  const onDragStart = () => props.dragStart(props.task);
  const onDragOver = () => props.dragOver(props.task);
  const onDragEnd = () => props.reorderTask();
  const classes = ['mb1', style(props.dark)];
  if (props.taskSorting.order && props.taskSorting.order === props.task.order) {
    if (props.taskSorting.task.order < props.task.order) classes.push('dropzone-down');
    else classes.push('dropzone-up');
  }
  return (
    <li
      className={classes.join(' ')}
      draggable={props.sortable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
    >
      <div className="toggle" onClick={toggleTask}>
        <Icon path={icon} size={0.7} />
      </div>
      <div className="description pl1 pr1">
        <div className={props.task.completedAt ? 'completed' : ''}>
          <input
            type="text"
            className="eip"
            defaultValue={props.task.description}
            onBlur={save}
            onKeyDown={onKeyDown}
            placeholder="Add a task"
          />
        </div>
        <small>{props.task.deadline ? props.task.deadline.slice(0, 10) : ''}</small>
      </div>
      {props.task.id ? <LabelPicker task={props.task} /> : null}
    </li>
  );
}
TaskItem.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    order: PropTypes.number,
    description: PropTypes.string,
    deadline: PropTypes.string,
    completedAt: PropTypes.string,
    projectId: PropTypes.number,
    labels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        colour: PropTypes.string
      })
    )
  }),
  taskSorting: PropTypes.shape({
    task: PropTypes.shape({
      id: PropTypes.number,
      order: PropTypes.number
    }),
    order: PropTypes.number
  }),
  sortable: PropTypes.bool,
  dark: PropTypes.bool,
  saveTask: PropTypes.func,
  destroyTask: PropTypes.func,
  toggleTask: PropTypes.func,
  dragStart: PropTypes.func,
  dragOver: PropTypes.func,
  reorderTask: PropTypes.func
};

TaskItem.defaultProps = {
  task: {
    labels: []
  }
};
