import gql from 'nanographql';

const queries = {
  getAll: gql`
    query {
      projects {
        id
        name
      }
      tasks {
        id
        order
        description
        projectId
        completedAt
        deadline
      }
      labels {
        id
        name
        colour
        tasks {
          id
        }
      }
    }
  `,
  getProjects: gql`
    query {
      projects {
        id
        name
      }
    }
  `,
  getTasks: gql`
    query {
      tasks {
        id
        description
        projectId
      }
    }
  `,
  getUser: gql`
    query {
      me {
        id
        email
      }
    }
  `,
  refreshToken: gql`
    mutation {
      refreshToken
    }
  `,
  login: gql`
    mutation($email: String!, $password: String!) {
      login(email: $email, password: $password)
    }
  `,
  register: gql`
    mutation($email: String!, $password: String!) {
      register(email: $email, password: $password)
    }
  `,
  resetPassword: gql`
    mutation($token: String!, $password: String!) {
      resetPassword(token: $token, password: $password)
    }
  `,
  forgotPassword: gql`
    mutation($email: String!) {
      forgotPassword(email: $email)
    }
  `,
  createProject: gql`
    mutation($name: String!) {
      createProject(name: $name) {
        id
        name
      }
    }
  `,
  updateProject: gql`
    mutation($id: Int!, $name: String!) {
      updateProject(id: $id, name: $name) {
        id
        name
      }
    }
  `,
  destroyProject: gql`
    mutation($id: Int!) {
      destroyProject(id: $id)
    }
  `,
  createLabel: gql`
    mutation($name: String!, $colour: String!) {
      createLabel(name: $name, colour: $colour) {
        id
        name
        colour
      }
    }
  `,
  updateLabel: gql`
    mutation($id: Int!, $name: String!, $colour: String!) {
      updateLabel(id: $id, name: $name, colour: $colour) {
        id
        name
        colour
      }
    }
  `,
  destroyLabel: gql`
    mutation($id: Int!) {
      destroyLabel(id: $id)
    }
  `,
  detachLabel: gql`
    mutation($labelId: Int!, $taskId: Int!) {
      detachLabel(labelId: $labelId, taskId: $taskId)
    }
  `,
  attachLabel: gql`
    mutation($labelId: Int!, $taskId: Int!) {
      attachLabel(labelId: $labelId, taskId: $taskId)
    }
  `,
  createTask: gql`
    mutation($description: String!, $deadline: String, $projectId: Int!, $labels: [Int]) {
      createTask(
        description: $description
        projectId: $projectId
        deadline: $deadline
        labels: $labels
      ) {
        id
        order
        description
        deadline
        projectId
      }
    }
  `,
  updateTask: gql`
    mutation(
      $id: Int!
      $description: String!
      $deadline: String
      $projectId: Int!
      $completedAt: String
    ) {
      updateTask(
        id: $id
        description: $description
        projectId: $projectId
        deadline: $deadline
        completedAt: $completedAt
      ) {
        id
        order
        description
        deadline
        completedAt
        projectId
      }
    }
  `,
  toggleTask: gql`
    mutation($id: Int!) {
      toggleTask(id: $id) {
        id
        order
        description
        deadline
        completedAt
        projectId
      }
    }
  `,
  reorderTask: gql`
    mutation($id: Int!, $order: Int!) {
      reorderTask(id: $id, order: $order) {
        id
        order
        description
        deadline
        completedAt
        projectId
      }
    }
  `,
  destroyTask: gql`
    mutation($id: Int!) {
      destroyTask(id: $id)
    }
  `
};

function wrapQueryRequest(http, query) {
  return (params) => {
    return http
      .post('/graphql', query(params))
      .then((res) => (res.body.errors ? Promise.reject(res.body) : res.body.data));
  };
}

export class GQL {
  constructor(http) {
    Object.keys(queries).forEach((name) => {
      this[name] = wrapQueryRequest(http, queries[name]);
    });
  }
}

GQL.require = ['http'];
