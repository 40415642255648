export const NOTIFY = 'NOTIFY';
export const CLEAR_TOAST = 'CLEAR_TOAST';
export const LEVELS = {
  WARNING: 'WARNING',
  INFO: 'INFO',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
};

export function notify(message, level = LEVELS.INFO, timeout = 3000) {
  return (dispatch) => {
    if (message && message.errors) {
      const messages = parseGraphQLErrors(message)
      return Promise.all(messages.map(m => dispatch(notify(m, level, timeout))));
    }
    const timestamp = Date.now();
    dispatch({
      type: NOTIFY,
      timestamp,
      level,
      message
    });
    setTimeout(() => dispatch(clearToast(timestamp)), timeout);
    if (level === LEVELS.ERROR) {
      return Promise.reject();
    }
  };
}

export function clearToast(timestamp) {
  return {
    type: CLEAR_TOAST,
    timestamp
  };
}

function parseGraphQLErrors(response) {
  return response.errors.map(e => e.message);
}
