import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, IconLink } from '../../shared';
import { get, map } from 'lodash';
import { css } from 'emotion';
import { mdiCheckboxBlankCircle, mdiCheckboxMarkedCircle } from '@mdi/js';

const style = css`
display: flex;
flex-direction: column;
flex-wrap: wrap;
width: 1em;
height: 1em;
.quarter {
  cursor: pointer;
  width: 0.5em;
  height: 0.5em;
  display: inline-block;
  background-color: var(--pie-color, transparent);
  &:first-of-type  { border-radius: 0.5em 0 0 0; border-left: solid 0px purple; border-top: solid 0px purple; }
  &:nth-of-type(2) { border-radius: 0 0 0 0.5em; border-left: solid 0px purple; border-bottom: solid 0px purple; }
  &:nth-of-type(3) { border-radius: 0 0.5em 0 0; border-right: solid 0px purple; border-top: solid 0px purple; }
  &:nth-of-type(4) { border-radius: 0 0 0.5em 0; border-right: solid 0px purple; border-bottom: solid 0px purple; }
}
`;

export class LabelPicker extends Component {
  state = { open: false };
  toggleDialog = (e) => {
    if (!e.isDefaultPrevented()) {
      this.setState({ open: !this.state.open });
    }
  };

  saveDeadline = (e) => {
    const task = {
      ...this.props.task,
      deadline: e.currentTarget.value.slice(0,10)
    };
    this.props.saveTask(task);
  };

  getSelected() {
    return map(this.props.task.labels, 'id');
  }

  getLabelColor(piece) {
    const { labels } = this.props;
    const selected = this.getSelected();
    let index = 0;
    switch (piece) {
      case 'bottomLeft':
        index = selected.length === 4 ? 2 : 0;
        break;
      case 'topRight':
        index =
          selected.length === 1 ? 0 :
            selected.length <= 3 ? 1 : 2;
        break;
      case 'bottomRight':
        index =
          selected.length === 1 ? 0 :
            selected.length === 2 ? 1 :
              selected.length === 3 ? 2 : 3;
        break;
    }
    return {
      '--pie-color': get(labels.find(l => l.id === selected[index]), 'colour', 'transparent'),
      'borderWidth': selected.length ? 0 : 1
    };
  }

  getLabelIcon(label) {
    return this.getSelected().includes(label.id) ? mdiCheckboxMarkedCircle : mdiCheckboxBlankCircle;
  }

  toggleLabel(label) {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      const method = this.getSelected().includes(label.id) ? 'detachLabel' : 'attachLabel';
      this.props[method](label, this.props.task);
    };
  }

  render() {
    return <div className={style} onClick={this.toggleDialog}>
      <div className="quarter" style={this.getLabelColor('topLeft')}></div>
      <div className="quarter" style={this.getLabelColor('bottomLeft')}></div>
      <div className="quarter" style={this.getLabelColor('topRight')}></div>
      <div className="quarter" style={this.getLabelColor('bottomRight')}></div>
      <Dialog title="Task settings" open={this.state.open} darken={true}>
        <label htmlFor="deadline">Deadline</label>
        <input id="deadline" name="deadline" type="date" value={this.props.task.deadline} onChange={this.saveDeadline} />
        <label className="mt1">Labels</label>
        <ul className="list-reset">
          { this.props.labels.map( label => (
            <li key={label.id}>
              <IconLink
                path={this.getLabelIcon(label)}
                iconStyle={{ color: label.colour }}
                onClick={this.toggleLabel(label)}>
                {label.name}
              </IconLink>
            </li>
          )) }
        </ul>
        <button onClick={this.toggleDialog} className="right">Close</button>
        <div className="clearfix"></div>
      </Dialog>
    </div>;
  }
}

LabelPicker.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    colour: PropTypes.string
  })),
  task: PropTypes.shape({
    id: PropTypes.number,
    deadline: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number
    }))
  }),
  attachLabel: PropTypes.func,
  detachLabel: PropTypes.func,
  saveTask: PropTypes.func
};

LabelPicker.defaultProps = {
  labels: [],
  task: {},
  attachLabel: () => false,
  detachLabel: () => false,
  saveTask: () => false
};