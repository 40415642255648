import { defaultsDeep, matches } from 'lodash';

/**
 * Mark a function parameter as required by assigning this as a parameter default
 * @param {String} name Name of the parameter
 * @throws Error when parameter is not given
 */
function requiredParameter(name) {
  throw new Error(`"${name}" is a required parameter`);
}

/**
 * @name HttpService
 * Http service for doing fetch requests. Currently only JSON data as payload is supported.
 * For the response both JSON and plain-text are supported.
 */
class HttpService {
  /**
   * Set a backend that performs the actual requests. Can be FetchWrapper or FetchMock.
   * @param {Object} backend Instance of backend
   */
  constructor(backend) {
    this.backend = backend;
  }
  /**
   * Check if backend is initialized
   */
  checkBackend() {
    if (!this.backend) {
      throw new Error('No backend defined for Http');
    }
  }
  /**
   * Perform a GET request
   * @param {String} url Destination url
   * @param {Object} options Options for request, may contain fetch options, i.e. headers, etc
   * @returns {Promise<Object>} Resolves with a response object
   */
  get(url, options) {
    this.checkBackend();
    return this.backend.fetch({
      url: url,
      options: options
    });
  }
  /**
   * Perform a DELETE request
   * @param {String} url Destination url
   * @param {Object} options Options for request, may contain fetch options, i.e. headers, etc
   * @returns {Promise<Object>} Resolves with a response object
   */
  delete(url, options = {}) {
    this.checkBackend();
    options.method = 'DELETE';
    return this.backend.fetch({
      url: url,
      options: options
    });
  }
  /**
   * Perform a POST request
   * @param {String} url Destination url
   * @param {Object|String} data Payload
   * @param {Object} options Options for request, may contain fetch options, i.e. headers, etc
   * @returns {Promise<Object>} Resolves with a response object
   */
  post(url, data, options = {}) {
    this.checkBackend();
    options.method = 'POST';
    return this.backend.fetch({ url, data, options });
  }
  /**
   * Perform a PUT request
   * @param {String} url Destination url
   * @param {Object|String} data Payload
   * @param {Object} options Options for request, may contain fetch options, i.e. headers, etc
   * @returns {Promise<Object>} Resolves with a response object
   */
  put(url, data, options = {}) {
    this.checkBackend();
    options.method = 'PUT';
    return this.backend.fetch({
      url: url,
      data: data,
      options: options
    });
  }
}

HttpService.require = ['httpBackend'];

/**
 * A response object
 * @property {Object} request The original request specification
 * @property {Number} status HTTP status
 * @property {String} statusText HTTP status text
 * @property {Any} body Response body
 */
class Response {
  constructor({
    request = requiredParameter('request'),
    status = requiredParameter('status'),
    statusText = requiredParameter('statusText'),
    body
  }) {
    this.request = request;
    this.status = status;
    this.statusText = statusText;
    this.body = body;
  }
}

/**
 * Mock a response object for testing purposes. Is returned by the FetchMock#expect function
 * @property {Object} request The original request
 * @property {Object} deferred The deferred instance with the ability to resolve/reject the promise
 * @property {Promise} promise The promise
 */
class MockResponse {
  constructor(request) {
    this.request = request;
    this.deferred = new Deferred();
    this.promise = this.deferred.promise;
  }
  /**
   * Respond to the request and resolve/reject the promise
   * @param {Response} response A response object
   */
  respondWith({ status = 200, statusText = 'OK', body = {} }) {
    let type = status < 400 ? 'resolve' : 'reject';
    this.deferred[type](
      new Response({
        body: body,
        status: status,
        statusText: statusText,
        request: this.request
      })
    );
  }
  /**
   * Fail the request
   * @param {Any} error Fail with error
   */
  fail(error) {
    this.deferred.reject(error);
  }
}

/**
 * A wrapper for Promises that allow the resolve/reject to occur outside Promise constructor. Much like `$q.defer`
 * @property {Promise} promise The promise that can be subscribed on
 * @property {Function} resolve A function to resolve the promise
 * @property {Function} reject A function to reject the promise
 */
class Deferred {
  constructor() {
    const d = this;
    this.promise = new Promise(function(resolve, reject) {
      d.resolve = resolve;
      d.reject = reject;
    });
  }
}

/**
 * A mock backend for testing purposes
 */
class FetchMock {
  constructor() {
    this.expectations = [];
  }
  /**
   * Perform a fetch and parse the response
   * @param {Object} request A request specification containing `url:string, [data:object, options:object]`
   * @return {Promise<Response>} Resolves with a response or throws an error on network- or response parsing failures
   */
  fetch(request) {
    let index = this.expectations.findIndex((expectation) => {
      return expectation.match(request);
    });
    if (index < 0) {
      throw new Error(`Unexpected request ${request.url}`);
    }
    let [expectation] = this.expectations.splice(index, 1);
    return expectation.response.promise;
  }
  /**
   * Add an expectation
   * @param {Object} request A request specification containing `url:string, [data:object, options:object]`
   * @return {MockResponse} An object to control how the request will be answered
   */
  expect({ url, data, options }) {
    const request = { url, data, options };
    request.response = new MockResponse(request);
    request.match = matches({ url, data, options });
    this.expectations.push(request);
    return request.response;
  }
  /**
   * Verify there are no pending expectations
   * @throws {Error} When there are still pending expectations
   */
  verifyNoPendingExpectations() {
    if (this.expectations.length) {
      let expecting = this.expectations.map((e, i) => `${i}. ${e.url}`);
      throw new Error(
        `There are ${this.expectations.length} pending expectations\n${expecting.join(
          '\n\t'
        )}`
      );
    }
  }
}

class FetchWrapper {
  constructor() {
    this.requestInterceptors = [];
    this.responseInterceptors = [];
  }
  /**
   * Add request interceptor that modifies request objects
   * @param {Function} fn Interceptor function
   */
  addResponseInterceptor(fn) {
    this.responseInterceptors.push(fn);
  }
  /**
   * Add request interceptor that modifies request objects
   * @param {Function} fn Interceptor function
   */
  addRequestInterceptor(fn) {
    this.requestInterceptors.push(fn);
  }
  /**
   * Set a base URL for requests that don't explicitly start with https?://
   * @param {String} url The base URL
   */
  setBaseUrl(url) {
    this.baseUrl = url;
  }
  /**
   * Perform a fetch and parse the response
   * @param {Object} request A request specification containing `url:string, [data:object|string, options:object]`
   * @return {Promise<Response>} Resolves with a response or throws an error on network- or response parsing failures
   */
  fetch({ url = requiredParameter('url'), data, options = {} }) {
    if (!/^https?:\/\//.test(url) && this.baseUrl) {
      url = this.baseUrl + url;
    }
    let request = defaultsDeep(options, {
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });
    if (data) {
      request.body = typeof data === 'string' ? data : JSON.stringify(data);
    }
    request = this.requestInterceptors.reduce((request, fn) => fn(request), request);
    return fetch(request.url, request).then(
      (res) => {
        let ContentType = res.headers.get('Content-Type');
        let type = /^application\/json/.test(ContentType) ? 'json' : 'text';
        return res[type]().then(
          (body) => {
            let resolve = res.status >= 400 ? 'reject' : 'resolve';
            if (/xml$/.test(ContentType)) {
              body = new DOMParser().parseFromString(body);
            }
            let response = new Response({
              body,
              request,
              status: res.status,
              statusText: res.statusText
            });
            response = this.responseInterceptors.reduce(
              (response, fn) => fn(response),
              response
            );
            return Promise[resolve](response);
          },
          () => Promise.reject(new Error(`Could not parse response body for ${url}`))
        );
      },
      () => new Error(`NetworkError fetching ${url}`)
    );
  }
}

export { Deferred, HttpService, FetchWrapper, FetchMock };
