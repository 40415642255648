import { notify, LEVELS } from '../toasts/actions';
import { fetchData } from '../data/actions';
import { get } from 'lodash';

export const SET_TOKEN = 'SET_TOKEN';
export const FETCHED_USER = 'FETCHED_USER';
export const LOGOUT = 'LOGOUT';

const handleAuthToken = (dispatch) => (response) => {
  dispatch(setToken(response.login || response.register || response.resetPassword || response.refreshToken));
  return Promise.all([
    dispatch(fetchUser()),
    dispatch(fetchData()),
  ]);
};

export function login(email, password) {
  return (dispatch, getState, { GQL }) =>
    GQL.login({ email, password }).then(
      handleAuthToken(dispatch),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
}

export function refreshToken() {
  return (dispatch, getState, { GQL }) => 
    GQL.refreshToken().then(
      handleAuthToken(dispatch),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
}

export function setToken(token) {
  return (dispatch) => {
    localStorage.setItem('token', token);
    return dispatch({
      type: SET_TOKEN,
      token
    });
  };
}

export function fetchUser() {
  return (dispatch, getState, { GQL }) => {
    return GQL.getUser().then(
      ({me}) => dispatch(fetchedUser(me)),
      (error) => {
        if (get(error, 'errors[0].message') === 'Unauthorized') {
          return dispatch(logout());
        }
        dispatch(notify(error, LEVELS.ERROR));
      }
    );
  };
}

export function fetchedUser(user) {
  return { type: FETCHED_USER, user };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem('token');
    return dispatch({
      type: LOGOUT
    });
  };
}

export function register(email, password) {
  return (dispatch, getState, { GQL }) => {
    return GQL.register({ email, password }).then(
      handleAuthToken(dispatch),
      (error) => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}

export function requestPasswordReset(email) {
  return (dispatch, getState, { GQL }) => (
    GQL.forgotPassword({ email }).then(
      () => dispatch(notify('Request sent, check your e-mail', LEVELS.INFO)),
      (error) => dispatch(notify(error, LEVELS.ERROR))
    )
  );
}

export function resetPassword(token, password) {
  return (dispatch, getState, { GQL }) => (
    GQL.resetPassword({ token, password }).then(
      handleAuthToken(dispatch),
      (error) => dispatch(notify(error, LEVELS.ERROR))
    )
  );
}