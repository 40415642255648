import { LOGOUT, FETCHED_USER, SET_TOKEN } from './actions';

export function currentUser(state = null, action) {
  switch(action.type) {
    case FETCHED_USER:
      return {
        ...state,
        ...action.user
      };
    case SET_TOKEN:
      return { token: action.token };
    case LOGOUT:
      return null;
  }
  return state;
}

