import { notify, LEVELS } from '../toasts/actions';
import { DateTime } from 'luxon';

export const CREATE_TASK = 'CREATE_TASK';
export const EDIT_TASK = 'EDIT_TASK';
export const DESTROY_TASK = 'DESTROY_TASK';
export const REORDER_TASKS = 'REORDER_TASKS';
export const DRAG_START = 'DRAG_START';
export const DRAG_OVER = 'DRAG_OVER';

export function saveTask(task) {
  return (dispatch, getState, { GQL }) => {
    const mutation = task.id ? 'updateTask' : 'createTask';
    const action = task.id ? editTask : createTask;
    return GQL[mutation](task).then(
      (data) => dispatch(action(data[mutation])),
      (error) => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}

export function toggleTask(task) {
  return (dispatch) =>
    dispatch(
      saveTask({
        ...task,
        completedAt: task.completedAt ? null : DateTime.local().toString()
      })
    );
}

export function createTask(task) {
  return {
    type: CREATE_TASK,
    task
  };
}

export function editTask(task) {
  return {
    type: EDIT_TASK,
    task
  };
}

export function destroyTask(task) {
  return (dispatch, getState, { GQL }) =>
    GQL.destroyTask(task).then(
      () => dispatch({ type: DESTROY_TASK, task }),
      (error) => dispatch(notify(error, LEVELS.ERROR))
    );
}

export function dragStart(task) {
  return {
    type: DRAG_START,
    task: task
  };
}

export function dragOver(task) {
  return {
    type: DRAG_OVER,
    task: task
  };
}

export function reorderTask() {
  return (dispatch, getState, { GQL }) => {
    const {
      taskSorting: {
        task: { id },
        order
      }
    } = getState();
    return GQL.reorderTask({ id, order }).then(
      (data) => dispatch({ type: REORDER_TASKS, tasks: data.reorderTask }),
      (error) => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}
