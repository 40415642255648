import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, IconLink } from '../../shared';
import { mdiCircleEditOutline, mdiPlus, mdiDotsVertical, mdiPlaylistCheck, mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { css } from 'emotion';
import swal from 'sweetalert';

const style = css`
  margin: 0;
  padding: 5px 0;
  list-style-type: none;
  >li { padding: 5px 3px; border-radius: 5px; }
  .highlight { background: #f0f0f0; }
  .menu { color: #e0e0e0; }
`;

export function ProjectList(props) {
  const onClickNew = (e) => {
    e.preventDefault();
    props.gotoNewProject();
  };
  const onClickProject = (project) => (e) => {
    e.preventDefault();
    props.gotoProjectTasks(project);
  };
  const onEditProject = (project) => (e) => {
    e.preventDefault();
    props.gotoEditProject(project);
  };
  const onDestroyProject = (project) => (e) => {
    e.preventDefault();
    swal({
      buttons: true,
      dangerMode: true,
      icon: 'warning',
      title: 'Delete project',
      text: 'Are you sure you want to delete this project and its tasks?'
    }).then((confirm) => {
      if(confirm) {
        props.destroyProject(project);
        props.gotoMain();
      }
    });
  };
  const isHighlighted = (id) => props.route.params.projectId === id;
  return <>
    <h5>Projects</h5>
    <ul className={style}>
      { props.projects.map(project => (
        <li className={isHighlighted(project.id) ? 'highlight' : ''} key={project.id}>
          <IconLink path={mdiPlaylistCheck} onClick={onClickProject(project)}>{project.name}</IconLink>
          <span className="right">
            <Dropdown trigger={<Icon path={mdiDotsVertical} size={.75} className="menu" /> } right>
              <DropdownItem>
                <IconLink onClick={onEditProject(project)} path={mdiCircleEditOutline}>Edit</IconLink>
              </DropdownItem>
              <DropdownItem>
                <IconLink onClick={onDestroyProject(project)} path={mdiDelete}>Delete</IconLink>
              </DropdownItem>
            </Dropdown>
          </span>
        </li>
      ))}
      <li><IconLink onClick={onClickNew} path={mdiPlus}>New project</IconLink></li>
    </ul>
  </>;
}

ProjectList.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  route: PropTypes.shape({
    params: PropTypes.object
  }),
  gotoMain: PropTypes.func,
  gotoEditProject: PropTypes.func,
  gotoProjectTasks: PropTypes.func,
  gotoNewProject: PropTypes.func,
  destroyProject: PropTypes.func
};