import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'js-md5';
import { has } from 'lodash';
import { css } from 'emotion';
import { Dropdown, DropdownItem, IconLink } from '../shared';
import { Icon } from '@mdi/react';
import { mdiChevronLeft, mdiPower } from '@mdi/js';

const style = css`
  background-color: #6c4260;
  color: white;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 52px;
  padding: 13px;
  text-align: center;
  .backBtn {
    position: absolute;
    left: 8px;
    top: 8px;
    color: white;
  }
  .pageTitle {
    margin: 0 auto;
  }
  .usermenu {
    text-align: left;
    position: absolute;
    top: 6px;
    right: 8px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin: 0 !important;
    }
  }
  @media (min-width: 768px) {
    .backBtn { display: none; }
  }
`;

export function NavBar(props) {
  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logout();
  };
  const onBackClick = (e) => {
    e.preventDefault();
    props.gotoMain();
  };

  const isBackBtnHidden = !props.currentUser || props.route.url === 'main';
  const gravatarUrl = has(props, 'currentUser.email') ? 
    `https://www.gravatar.com/avatar/${md5(props.currentUser.email)}?s=50` : '';
  const isLoggedIn = !!props.currentUser;
  return (
    <nav className={style} role="navigation" aria-label="main navigation">
      <span className="pageTitle">Elepho</span>
      <a className="backBtn"
        onClick={onBackClick}
        hidden={isBackBtnHidden}>
        <Icon path={mdiChevronLeft} size={1.5} />
      </a>
      <div hidden={!isLoggedIn} className="usermenu">
        <Dropdown right trigger={<img src={gravatarUrl} />}>
          <DropdownItem><IconLink path={mdiPower} onClick={onLogoutClick}>Logout</IconLink></DropdownItem>
        </Dropdown>
      </div>
    </nav>
  );
}

NavBar.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string
  }),
  route: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired
  }).isRequired,
  pageTitle: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  gotoMain: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};