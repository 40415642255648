import React from 'react';
import PropTypes from 'prop-types';

export function ProjectForm(props) {
  const onSubmit = (e) => {
    e.preventDefault();
    const project = { name: e.currentTarget.name.value };
    if (props.project.id) {
      project.id = props.project.id;
    }
    props.saveProject(project).then(() => {
      return props.gotoMain();
    });
  };
  const title = props.project.id ? 'Edit project' : 'New project';
  return <form onSubmit={onSubmit}>
    <h5 className="m0 mb2">{ title }</h5>
    <label htmlFor="name">Name</label>
    <input type="text" id="name" name="name" defaultValue={props.project.name} />
    <button className="mt1 right">Save</button>
  </form>;
}

ProjectForm.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  saveProject: PropTypes.func,
  gotoMain: PropTypes.func
};

ProjectForm.defaultProps = {
  project: { name: '' },
  saveProject: () => Promise.reject()
};