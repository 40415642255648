import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import {mdiImageBrokenVariant } from '@mdi/js';
import { css } from 'emotion';
import { omit } from 'lodash';

const style = css`
  cursor: pointer;
  svg {
    position: relative;
    top: -2px;
  }
`;
export function IconLink(props) {
  const linkProps = omit(props, ['iconStyle', 'path', 'children', 'size']);
  linkProps.className = `${linkProps.className || ''} ${style}`;
  return <a {...linkProps}>
    <span style={props.iconStyle} className="pr1"><Icon path={props.path} size={props.size} /></span>
    { props.children }
  </a>;
}

IconLink.propTypes = {
  path: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  iconStyle: PropTypes.object
};

IconLink.defaultProps = {
  path: mdiImageBrokenVariant,
  size: .75,
  iconStyle: {}
};