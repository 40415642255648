import { notify, LEVELS } from '../toasts/actions';

export const CREATE_PROJECT = 'CREATE_PROJECT';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const DESTROY_PROJECT = 'DESTROY_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';

export function saveProject(project) {
  return (dispatch, getState, { GQL }) => {
    const mutation = project.id ? 'updateProject' : 'createProject';
    const action = project.id ? editProject : createProject;
    return GQL[mutation](project).then(
      data => dispatch(action(data[mutation])),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
  };
}

export function createProject(project) {
  return {
    type: CREATE_PROJECT,
    project
  };
}

export function editProject(project) {
  return {
    type: EDIT_PROJECT,
    project
  };
}

export function destroyProject(project) {
  return (dispatch, getState, { GQL }) =>
    GQL.destroyProject(project).then(
      () => dispatch({ type: DESTROY_PROJECT, project: project }),
      error => dispatch(notify(error, LEVELS.ERROR))
    );
}
